<template>
  <svg
    width="16"
    height="13"
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5234 1.52734C15.5742 3.25 15.2734 6.06641 13.8242 7.57031L9.01172 12.4648C8.73828 12.7383 8.38281 12.9023 8 12.9023C7.58984 12.9023 7.23438 12.7383 6.96094 12.4648L2.17578 7.57031C0.699219 6.06641 0.398438 3.25 2.44922 1.52734C4.03516 0.1875 6.46875 0.378906 8 1.9375C9.50391 0.378906 11.9375 0.1875 13.5234 1.52734ZM12.8672 6.64062C13.8789 5.62891 14.0703 3.71484 12.6758 2.51172C11.6094 1.63672 9.96875 1.77344 8.95703 2.8125L8 3.79688L7.04297 2.8125C6.00391 1.77344 4.36328 1.63672 3.29688 2.53906C1.90234 3.71484 2.09375 5.62891 3.10547 6.64062L7.89062 11.5352C7.97266 11.5898 8.02734 11.5898 8.08203 11.5352L12.8672 6.64062Z"
      fill="currentColor"
    />
  </svg>
</template>
